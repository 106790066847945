@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit/OutfitBold.ttf") format("truetype"),
    url("./assets/fonts/Outfit/OutfitRegular.ttf") format("truetype"),
    url("./assets/fonts/Outfit/OutfitLight.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit/OutfitBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit/OutfitRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit/OutfitLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

.font-outfit {
  font-family: 'Outline', sans-serif;
}

.font-outfit-bold {
  font-family: "Outfit", sans-serif;
  font-weight: bold;
}

.font-outfit-regular {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
}

.font-outfit-light {
  font-family: "Outfit", sans-serif;
  font-weight: 300;
}
