@keyframes backgroundAnimation {
  0% {
    opacity: 0.6;
    background: #040102;
  }
  25% {
    opacity: 0.6;
    background: #9a7630;
  }
  50% {
    opacity: 0.6;
    background: #791f22;
  }
  75% {
    opacity: 0.6;
    background: #242a6f;
  }
  100% {
    opacity: 0.6;
    background: #040102;
  }
}

.animated-background {
  position: absolute;
  inset: 0;
  z-index: -1;
  animation: backgroundAnimation 10s infinite alternate;
}

@keyframes textAnimation {
  0% {
    content: "TOBACCO";
  }
  25% {
    content: "GOLD";
  }
  50% {
    content: "RED";
  }
  75% {
    content: "BLUE";
  }
  100% {
    content: "TOBACCO";
  }
}

.animated-text::after {
  content: "";
  animation: textAnimation 10s infinite alternate;
}
